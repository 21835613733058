import { Table } from "react-bootstrap";
import img1 from "./assets/image1.png";
import img11 from "./assets/image11.png";
import img12 from "./assets/image12.png";
import img13 from "./assets/image13.png";
import img14 from "./assets/image14.png";
import img2 from "./assets/image2.png";
import img3 from "./assets/image3.png";
import img4 from "./assets/image4.png";
import img5 from "./assets/image5.png";
import img7 from "./assets/image7.png";

function App() {
    return (
        <div class="container">
            <div class="logo">
                <img src={"https://d31t17jekhn3g5.cloudfront.net/home-logo-new.png"} alt="logo" />
                <h1>Escape Earth Guide</h1>
            </div>
            <h3 class="version">Edition of 12.09.2024</h3>
            <div className="d-md-flex gap-3 mt-3">
                <div>
                    <h2 class="title my-3">Overview</h2>
                    <p class="content">
                        Escape Earth is a fast-paced endless runner where players navigate planets, dodging obstacles and collecting coins to progress through four stages: Bronze, Silver, Gold, and Diamond. The game features skill upgrades, power-ups, dynamic terrain, and a staking system for in-game tokens. Players can earn additional Coins through daily rewards, challenges, and social media engagement.
                    </p>
                </div>
                <img src={img5} alt="guide" />
            </div>
            <h2 className="title mt-4">How to play </h2>
            <p className="content mt-3">
                In this game, you play an infinite runner, collecting coins to increase your score as you progress.
            </p>
            <div className="d-md-flex gap-3 mt-5">
                <img src={img7} alt="guide" />
                <img src={img3} alt="guide" />
            </div>
            <h2 className="title mt-3">Level Up</h2>
            <ul className="mt-2">
                <li>
                    The game will have 4 character stages.
                    <ul>
                        <li>Stage 1: Bronze
                            <ul>
                                <li>Collect 100,000 game Coins to become eligible for the next stage.</li>
                            </ul>
                        </li>
                        <li>Stage 2: Silver
                            <ul>
                                <li>Collect 1,100,000 game Coins to become eligible for the next stage.</li>
                            </ul>
                        </li>
                        <li>Stage 3: Gold
                            <ul>
                                <li>Collect 6,000,000 game Coins to become eligible for the next stage.</li>
                            </ul>
                        </li>
                        <li>Stage 4: Diamond
                            <ul>
                                <li>Collect 6,60,00,000 game points to complete this stage.</li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li>
                    Upon the successful completion of each stage, the player will be awarded 100 game Coins.
                </li>
            </ul>
            <div className="d-md-flex gap-3 mt-5">
                <div>
                    <h2 className="title">Invite Friends</h2>
                    <p className="content">You will have the option to invite your friends to the gameplay and earn 500 game Coins.</p>
                </div>
                <img src={img11} alt="guide" />
            </div>
            <div className="d-md-flex gap-3 mt-5">
                <div>
                    <h2 className="title">Earn more Coins</h2>
                    <ul>
                        <li>In this game, you need to complete activities to earn Coins:
                            <ul>
                                <li>
                                    Join our Telegram channel – 500 Coins
                                </li>
                                <li>
                                    Follow us on different social media platforms
                                    <ul>
                                        <li>Follow X account – 500 Coins</li>
                                        <li>Join our Discord channel – 500 Coins</li>
                                        <li>Learn about the CrossFi ecosystem</li>
                                        <li>Watch YouTube video 1 – 500 Coins</li>
                                        <li>Watch YouTube video 2 – 500 Coins</li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <img src={img14} alt="guide" />
            </div>
            <div className="d-md-flex gap-3 mt-5">
                <div>
                    <h2 className="title">Daily Reward</h2>
                    <p className="content">You will receive daily reward, which will continue increasing until the 10th day and then reset every 10 days. If you fail to claim your daily reward the next day, the cycle will reset and start from the beginning.</p>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>Days</th>
                                <th>Coins earned</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                [
                                    { key: 1, value: 50 },
                                    { key: 2, value: 100 },
                                    { key: 3, value: 150 },
                                    { key: 4, value: 200 },
                                    { key: 5, value: 250 },
                                    { key: 6, value: 300 },
                                    { key: 7, value: 350 },
                                    { key: 8, value: 400 },
                                    { key: 9, value: 450 },
                                    { key: 10, value: 500 }
                                ].map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{item.key}</td>
                                            <td>{item.value}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                </div>
                <img src={img1} alt="guide" />
            </div>
            <div className="d-md-flex gap-3 mt-5">
                <div>
                    <h2 className="title">Daily Challenge</h2>
                    <p className="content">The player needs to complete the mage challenge within 20 seconds to earn 500 coins.</p>
                    <p className="content">
                        <b>Note:</b>
                        Collecting the daily reward and completing the daily challenge together will award you 100 game tokens.
                    </p>
                </div>
                <img src={img12} alt="guide" />
            </div>
            <div className="mt-4">
                <div>
                    <h2 className="title">Marketplace</h2>
                    <ul>
                        <li>
                            Power-ups and Boosts:
                            <ul>
                                <li>You will be able to buy power-ups from the marketplace using the in-game coins earned, such as:
                                    <ul>
                                        <li><b>Speed Boosts:</b> Temporarily increases your running speed.</li>
                                        <li><b>Shield:</b> Grants you temporary invincibility against obstacles.</li>
                                        <li><b>Magnet:</b> Attracts coins and power-ups within a certain radius.</li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li>
                            Customization:
                            <ul>
                                <li>Character Skins:
                                    <ul>
                                        <li>Unlock different skins for your character at various stages or purchase them using in-game coins.</li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div className="d-md-flex gap-3">
                    <img src={img13} alt="guide" />
                    <img src={img4} alt="guide" />
                </div>
            </div>
            <div className="d-md-flex gap-3 mt-5">
                <div>
                    <h2 className="title">Wallet</h2>
                    <p className="content">You can connect your wallet with MetaMask and earn 500 coins.</p>
                </div>
                <img src={img2} alt="guide" />
            </div>
            {/* <div className="mt-4">
                <div>
                    <h2 className="title">Steak and earn points </h2>
                    <ul>
                        <li>
                            The staking module will be enabled if you have collected 50k game tokens. Once eligible, you will be able to stake your in-game tokens and earn daily returns. The amount of tokens staked will contribute towards the TVL (Total Value Locked) of the game. You will have the following options to stake your tokens:
                            <ul>
                                <li>Stake for 30 days: Get 0.1% daily return</li>
                                <li>Stake for 60 days: Get 0.2% daily return</li>
                                <li>Stake for 90 days: Get 0.4% daily return</li>
                                <li>Stake for 180 days: Get 0.8% daily return</li>
                            </ul>
                        </li>
                        <li>
                            If you unstake your tokens before the staking period ends, you will only receive the principal amount, reduced by 10%.
                        </li>
                    </ul>
                </div>
                <div className="d-md-flex gap-3">
                    <img src={img8} alt="guide" />
                    <img src={img10} alt="guide" />
                </div>
            </div>
            <div className="d-md-flex gap-3 mt-5">
                <div>
                    <h2 className="title">Haptic effect</h2>
                    <p className="content">Integration of haptic feedback which will result in vibrations on every tap.
                    </p>
                </div>
                <img src={img9} alt="guide" />
            </div> */}
        </div>
    );
}

export default App;
